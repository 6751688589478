import React from 'react';
import Wrapper from '../../UI/Wrapers/Wrapper/Wrapper';
import TalantIndex from '../../images/portfolio/Talant.png'
import TalantPage from '../../images/portfolio/Talant (3).png'
import DocIndex from '../../images/portfolio/DocScreenshot_1.png'
import DocProperties from '../../images/portfolio/DocScreenshot_2.png'
import DocMarket from '../../images/portfolio/DocScreenshot_3.png'
import DocForm from '../../images/portfolio/Screenshot_1.png'
import RadixCalc from '../../images/portfolio/radixcalc.png'
import TypusMain from '../../images/portfolio/typusmain.png'
import TypusRooms from '../../images/portfolio/typusrooms.png'
import TypusSettings from '../../images/portfolio/typussettings.png'

import styles from './PortfolioNav.module.css'
const projects = [
    {
        title: 'Typus',
        description: 'Тренажёр для онлайн соревнований в скорости печати.',
        link: 'https://www.typus.ga',
        images: [
            TypusMain,
            TypusRooms,
            TypusSettings
        ]
    },
    {
        title: 'Radix calculator',
        description: 'переводчик из любой системы исчиления в любую другую с учётом периода',
        link: 'https://react-redix-calculator.vercel.app/',
        images: [
            RadixCalc
        ]
    },
    {
        title: 'Talant',
        description: 'стартап по созданию профориентационной площадки. Сайт писал с Backend разработчиком на django, соответственно я отвечал за весь frontend и дизайн.',
        images: [
            TalantIndex,
            TalantPage
        ]
    },
    {
        title: 'DOC',
        description: 'Сайт на тильде для продажи handmade масок.',
        link: 'https://doctorscinmask.tilda.ws/',
        images: [
            DocIndex,
            DocProperties,
            DocMarket,
            DocForm
        ]
    }
]
const PortfolioNav = () => {
    return (
        <Wrapper>
            <h2 className={styles.title}>Портфолио</h2>
            {projects.map(({ description,images,link,title}, index) => {
                return (
                    <div className={styles.project} key={index}>
                        <div className={styles.projectHeader}>
                            <h3>{title}</h3>
                            <p>{description + ' '}</p>
                            {link&&<a href={link} target='_blank' className={styles.link}>Перейти на сайт →</a>}
                        </div>
                        <div className={styles.projectImages}>
                            {images.map((image, index) => {
                                return (
                                    <img src={image} alt="" key={index}/>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </Wrapper>

    );
};

export default PortfolioNav;
