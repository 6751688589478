import React from 'react';
import styles from './index.module.css'
import img from '../../images/IndexImages/feduk.png'
import Navigation from '../Navigation/Navigation';
import ContextButton from '../../UI/Buttons/contextButton/ContextButton';
import BlogNav from '../Blog/BlogNav/BlogNav';
import PortfolioNav from '../Portfolio/PortfolioNav';
const Index = () => {
    return (
        <>
            <div className={styles.background}>
                <div className={styles.container}>
                    <div className='glichRedBlue'>
                        <h1>ПРИВЕТ! Я ФЁДОР,</h1>
                        <h1>UX,UI-<h2 className='redText'>ДИЗАЙНЕР</h2> И</h1>
                        <h1><h2 className='redText'>РАЗРАБ</h2>ОТЧИК</h1>
                        <h1>САЙТОВ</h1>
                        <div>
                            <p className={styles.subtitle}>Welcome to my website!</p>
                            <hr className={styles.line}/>
                            <a href="#Navigation"><ContextButton>обзор</ContextButton></a>
                        </div>
                    </div>
                    <div className={styles.circle}><img className={styles.img} src={img}/></div>
                </div>
            </div>
            <BlogNav />
            <PortfolioNav />

            {/*<Navigation/>*/}
        </>
    );
};

export default Index;