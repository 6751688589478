import React from 'react';
import Wrapper from '../../../UI/Wrapers/Wrapper/Wrapper';
import LogicOperationsDiagram from './LogicOperationsDiagram/LogicOperationsDiagram';
import NotDiagram from './NotDiagram/NotDiagram';

const LogicOperations = () => {
    return (
        <>
            <Wrapper  title={'Логические операции'}>

                <p>Часто программисту приходится использовать условия при написании кода и когда их становиться больше одного люди часто начинают неоправданно вставлять дополнительные отрицания и др.
                    После этого код хоть и будет работать, но его будет неудобно читать и понимать. </p>
                <p><em>Логическая операция</em>— операция над высказываниями, позволяющая составлять новые высказывания путём соединения более простых
                </p>
                <p>В качестве основных обычно называют конъюнкцию(and),  дизъюнкцию(or) отрицание(or).</p>
            </Wrapper> 
            <NotDiagram/>
            <LogicOperationsDiagram/>
            <Wrapper>
            <h4>как все операции приводить к and, or, not</h4>
                <p>если вы поймете что вам нужно использовать одну
                    из нестандартных операций(например XOR) вам нужно будет либо
                    искать её в вашем языке программирования, либо (и чаще всего лучше всего)
                    выразить ее в стандартных операциях(and, or, not). На самом деле чаще всего в
                    условиях получается обойтись без формул, но знать что такое есть будет полезно.</p>
                    <ul>
                        <li>импликация (следование)  A→B = A or B</li>
                        <li>эквивалентность (тождество)  A↔B = (A and B) or (not A and not B)</li>
                        <li>относительное дополнение (разность)  A\B = A and not B</li>
                        <li>симметрическая разность  A△B = not A and B or A and notB</li>

                    </ul>
                <em>напоминаю что основные логические операции выполняются в последовательности not, and, or .Это можно учитывать при расставлении скобок
                </em>


                <h4>как упрощать логические выражения (продвинутый уровень)</h4>
                <p>В этом нам помогут законы алгебра логики.
                    Самые часто используемое это законы де Моргана:</p>
                <ul>
                    <li>отрицание конъюнкции - ~(A * B) = ~A + ~B</li>
                    <li>отрицание дизъюнкции - ~(A + B) = ~A * ~B</li>
                </ul>
                <p>выбирайте использовать или нет в зависимости от того что будет понятнее.
                    Например:
                    нам нужно чтобы число не подходило под параметры: делится на 5 и не делится на 12.
                    Так и запишем: `not(x%5==0 and not x%12==0)` применяем закон де Моргана, отрицания сокращаются и мы получаем краткое и понятное
                    `not x%5==0 or x%12==0`
                </p>
                <p>остальные на мой взгляд если и применимы, но ооочень редко пригождаются
                    Если интересно, то вот
                </p>
                <p>A+AB+ABC=A (если в есть слагаемые содержащие одно из них то оно сокращается)</p>
                <ul>
                    <li>отрицание конъюнкции - ~(A * B) = ~A + ~B</li>
                    <li>отрицание дизъюнкции - ~(A + B) = ~A * ~B</li>
                </ul>
                <p>A+AB+ABC=A (если в есть слагаемые содержащие одно из них то оно сокращается)</p>
                <em>вынесение</em>
                <ul>
                    <li>A * B + A * C = A * (B + C)</li>
                    <li>(A + B) * (A + C) = A + B * C</li>
                </ul>
                <em>поглощение</em>
                <ul>
                    <li>A * (A + B) = A</li>
                    <li>A + A * B = A</li>
                </ul>
                <em>склеивание</em>
                <ul>
                    <li>(A+B)(A+notB)=A</li>
                    <li>AB+AnotB=A</li>
                </ul>
                <em>расширение</em>
                <ul>
                    <li>AB+notAC+BC=AB+notAC</li>
                    <li>A+B)(notA+C)(B+C)=(A+B)(notA+C)</li>
                </ul>
            </Wrapper>
        </>

    );
};

export default LogicOperations;