import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.getElementById('html').style.scrollBehavior='auto'
        setTimeout(()=>{window.scrollTo(0, 0);},1)
        // window.scrollTo(0, 0);
        setTimeout(()=>{document.getElementById('html').style.scrollBehavior='smooth'},500)
    }, [pathname]);
    return null;
}