import React, {useEffect} from 'react';
import styles from './header.module.css'
import {Link} from 'react-router-dom';

const Header = () => {
    let isDark = true
    //TODO useState
    function switcher() {
        if (isDark) {
            document.body.classList.add('white');
        } else {
            document.body.classList.remove('white');
        }
        isDark = !isDark
    }
    function MenuClose(){
        document.getElementById('headerToggle').click()
        console.log('fff')
    }

    return (

        <>

            <div  className={styles.menuToggle}>
                <input type="checkbox" id='headerToggle'/>
                <span></span>
                <span></span>
                <span></span>
                <ul onClick={MenuClose} className={styles.menu}>
                    <li><Link to="/">Главная</Link></li>
                    <li><Link to="/sites">Заказать сайт</Link></li>
                    <li><Link to="/sandbox">Sandbox</Link></li>
                    <li><Link to="/blog">Блог</Link></li>
                </ul>
            </div>
            <header>
                <Link to='/' className={styles.logo}>Feduk</Link>
                <ul className={styles.headerlist}>
                    <li><Link to="/">Главная</Link></li>
                    <li><Link to="/sites">Заказать сайт</Link></li>
                    <li><Link to="/sandbox">Sandbox</Link></li>
                    <li><Link to="/blog">блог</Link></li>
                </ul>
                <div className={styles.buttons}>
                    <input type='checkbox' className={styles.swicher} onClick={switcher}/>
                </div>
            </header>
        </>
    );


};

export default Header;