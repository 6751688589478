import styles from './App.css';
import Index from './pages/index/Index';
import Header from './Components/header/header';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Sandbox from './pages/sandbox/Sandbox';
import MyButtons from './UI/Buttons/myButtons/MyButtons';
import TVfilter from './UI/TVfilter/TVfilter';
import Footer from './Components/Footer/Footer';
import ContentfulBlog from './pages/Blog/ContantfullBlog/ContentfulBlog';
import Blog from './pages/Blog/BlogRouter';
import LogicOperations from './pages/Blog/LogicOperations/LogicOperations';
import React from 'react';
import {Outlet} from "react-router-dom";
import BlogRouter from './pages/Blog/BlogRouter';
import ScrollToTop from './Components/ScrollToTop';

function App() {

    return (
        <>
            <BrowserRouter>
                <TVfilter/>
                <Header/>
                <ScrollToTop/>
                <Routes>

                    <Route exact path={'/'} element={<Index/>}/>
                    <Route exact index path={'/'} element={<Index/>}/>
                    <Route path={'blog/*'} element={<BlogRouter/>}/>
                    <Route path={'*'} element={
                        <div style={{height:'80vh',textAlign:'center'}}>
                            <h2 style={{padding:'100px 0 0 0',margin:'0 0 0 0!important'}}> There's nothing here!</h2>
                        </div>
                    }/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    );
};

export default App;
