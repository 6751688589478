import React from 'react';
import styles from './Footer.module.css'

const Footer = () => {
    return (
        <>
            <footer>
                <div className={styles.Footer}>
                    <div>
                        <h3>Let's Work Together —</h3>
                        <a className='link' href={'mailto:fedor20052000@gmail.com'}>fedor20052000@gmail.com</a>
                    </div>
                    <div>
                        <h3>Find me here —</h3>
                        <a className='link' href={'#'}>Instagramm</a><br/>
                        <a className='link' href={'#'}>Behance</a><br/>
                        <a className='link' href={'#'}>Youtube</a>
                    </div>
                </div>
                <p className={styles.Copyright}>All content and images Copyright © 2020 Reshetnikov Fedor</p>
            </footer>

        </>
    );
};

export default Footer;