import React, {useEffect, useState} from 'react';
import styles from './LogicOperations.module.css'
import {ReactComponent as Skeleton} from '../../../../images/algebraLogic/skeleton.svg'
import {ReactComponent as RightCircle} from '../../../../images/algebraLogic/rightCircle.svg'
import {ReactComponent as LeftCircle} from '../../../../images/algebraLogic/leftCircle.svg'
import {ReactComponent as BothCircles} from '../../../../images/algebraLogic/bothCircles.svg'
import {ReactComponent as WithoutCircles} from '../../../../images/algebraLogic/withoutCircles.svg'
import Wrapper from '../../../../UI/Wrapers/Wrapper/Wrapper';


const LogicOperationsDiagram = () => {
    const [logic,setLogic] = useState([0,1,1,1])
    const [diagramArr,setDiagramArr] = useState(['fadeOut','fadeOut','fadeOut','fadeIn'])
    const [symbol,setSymbol] = useState('or')
    const [explainTitle,setExplainTitle] = useState('OR, ∪, +, ∨')
    const [explain,setExplain] = useState('')
    useEffect(()=>{Change()},logic)
    function Change(){
        let copy = []
        for (let i = 0; i < 4; i++){
            if (logic[i]===0){
                copy[i]='fadeOut'
            }else {copy[i]='fadeIn'}
        }
        setDiagramArr(copy);
    }
    function And(){
        setLogic([0,0,0,1])
        setSymbol('and')
        setExplainTitle('AND, &, ∩, ^, *')
        setExplain('конъюнкция (логическое «и»)')
    }
    function Or(){
        setLogic([0,1,1,1])
        setSymbol('or')
        setExplainTitle('OR, ∪, +, ∨')
        setExplain('дизъюнкция (логическое или)')
    }
    function Xor(){
        setLogic([0,1,1,0])
        setSymbol('xor')
        setExplainTitle('△, XOR')
        setExplain('симметрическая разность')

    }
    function Arrow(){
        setLogic([1,1,0,1])
        setSymbol('→')
        setExplainTitle('→')
        setExplain('импликация (следование)')
    }
    function Equals(){
        setLogic([1,0,0,1])
        setSymbol('↔')
        setExplainTitle('- , \\')
        setExplain('эквивалентность (тождество)')
    }
    function Minus(){
        setSymbol('-')
        setLogic([0,0,1,0])
        setExplainTitle('AND, &, ∩, ^, *')
        setExplain('относительное дополнение (разность)')
    }

    return (
        <Wrapper>
            <div className={styles.diagramAndTable}>
                <div className={styles.diagramWrapper}>
                    <center><h3>Диаграмма Эйлера A&nbsp;{symbol}&nbsp;B&nbsp;=&nbsp;f</h3></center>
                    <div className={styles.diagram}>

                        <Skeleton className={styles.skeleton}/>
                        <WithoutCircles className={styles.WithoutCircles + ' ' + styles[diagramArr[0]]}/>
                        <RightCircle className={styles.RightCircle + ' ' + styles[diagramArr[1]]}/>
                        <LeftCircle className={styles.LeftCircle + ' ' + styles[diagramArr[2]]}/>
                        <BothCircles className={styles.BothCircles + ' ' + styles[diagramArr[3]]}/>
                    </div>
                </div>

                <div className={styles.tableWrapper}>
                    <h3>Таблица истинности A&nbsp;{symbol}&nbsp;B&nbsp;=&nbsp;f</h3>
                    <div className={styles.table}>
                        <table>
                            <tr>
                                <td><h3>A</h3></td>
                                <td><h3>B</h3></td>
                                <td><h3>f</h3></td>
                            </tr>
                            <tr>
                                <td><h3>0</h3></td>
                                <td><h3>0</h3></td>
                                <td><h3>{logic[0]}</h3></td>
                            </tr>
                            <tr>
                                <td><h3>0</h3></td>
                                <td><h3>1</h3></td>
                                <td><h3>{logic[1]}</h3></td>
                            </tr>
                            <tr>
                                <td><h3>1</h3></td>
                                <td><h3>0</h3></td>
                                <td><h3>{logic[2]}</h3></td>
                            </tr>
                            <tr>
                                <td><h3>1</h3></td>
                                <td><h3>1</h3></td>
                                <td><h3>{logic[3]}</h3></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div id={'explain'} className={styles.explainWrapper}>
                <center><h3 className={styles.explainTitle}>{explainTitle}</h3></center>
                <center><p className={styles.explain}>{explain}</p></center>
            </div>
            <center><p className={styles.pickOne}>pick one:</p></center>
            <div className={styles.logicOperationButtons}>
                <button onClick={And}>and</button>
                <button onClick={Or}>or</button>
                <button onClick={Xor}>xor</button>
                <button onClick={Arrow}>→</button>
                <button onClick={Equals}>↔</button>
                <button onClick={Minus}>-</button>
            </div>
        </Wrapper>
    );

};

export default LogicOperationsDiagram;