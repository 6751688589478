import React from 'react';
import {Link} from 'react-router-dom';
import styles from './BlogNav.module.css'
import Wrapper from '../../../UI/Wrapers/Wrapper/Wrapper';
import LogicOperationsPreview from '../../../images/BlogPreviewImages/LogicOperations.png';


const BlogNav = () => {
    return (
        <div className={styles.background} id={'Navigation'}>
            <div className={styles.wrapper} title={'Blog'}>
                <p>RECENTLY PUBLISHED</p>
                <div className={styles.links}>
                    <Link to={'/blog/post1'}>
                        <img src={LogicOperationsPreview} alt=""/>
                        <div className={styles.textArea}>
                            <h3>Logic operations & algebra-logic</h3>
                            <p>Часто программисту приходится использовать условия при написании кода и когда их становиться больше одного люди часто начинают неоправданно вставлять дополнительные отрицания и др.</p>
                            <p>После этого код хоть и будет работать, но его будет неудобно читать и понимать. </p>
                            <a href="" className='link'><strong>Read more→</strong></a>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BlogNav;