import React, {useState} from 'react';
import {ReactComponent as Skeleton} from '../../../../images/algebraLogic/NotSceleton.svg';
import {ReactComponent as Circle} from '../../../../images/algebraLogic/NotCircle.svg';
import {ReactComponent as Rect} from '../../../../images/algebraLogic/NotRectangle.svg';
import styled from 'styled-components';
import Wrapper from '../../../../UI/Wrapers/Wrapper/Wrapper';
import styles from './NotDiagram.module.css'
import outStyles from './../LogicOperationsDiagram/LogicOperations.module.css'


const NotDiagram = () => {
    const[circle,setCircle]=useState('fadeIn')
    const[rect,setRect]=useState('fadeOut')
    function Change(){
        if (circle==='fadeOut'){
            setCircle('fadeIn')
            setRect('fadeOut')
        }
        else{
            setCircle('fadeOut')
            setRect('fadeIn')
        }
    }
    return (
        <Wrapper>
            <div className={styles.wrapper}>
                <div>
                    <p>На диаграмме Эйлера показаны области где переменная положительна в виде круга.
                        Вот и первая операция, которая кстати единственная работает с одним операндом - это отрицание
                    </p>
                    <button onClick={Change} className={styles.notButton}>NOT</button>
                </div>
                <div className={styles.wrapDiagram}>
                    <Skeleton/>
                    <Circle className={outStyles[circle]}/>
                    <Rect className={outStyles[rect]}/>
                </div>
            </div>
        </Wrapper>
    );
};

export default NotDiagram;