import React from 'react';
import styles from './Wrapper.module.css'
import styled,{css} from 'styled-components';

const Wrapper = (
    {
        view='regular',
        children,
        title=''
    }
) => {

    if (title===''){
        return (
            <>
                <div className={styles[view]}>
                    {children}
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div className={styles.topWrapper}><center><h3>{title}</h3></center></div>
                <div className={styles[view]}>
                    {children}
                </div>
            </>

        );
    };
};

export default Wrapper;