import React from 'react';
import LogicOperations from './LogicOperations/LogicOperations';
import LogicOperationsDiagram from './LogicOperations/LogicOperationsDiagram/LogicOperationsDiagram';
import {Route, Switch, Routes, Link} from 'react-router-dom';
import BlogNav from './BlogNav/BlogNav';

const BlogRouter = () => {
    return (
        <>
            <Routes>
                <Route exact path={'/'} element={<BlogNav/>}/>
                <Route path={'post1'} element={<LogicOperations/>}/>
            </Routes>


        </>
    );
};

export default BlogRouter;