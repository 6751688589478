import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "./App";
import TVfilter from './UI/TVfilter/TVfilter';
import Header from './Components/header/header';
import Index from './pages/index/Index';
import Sandbox from './pages/sandbox/Sandbox';
import BlogRouter from './pages/Blog/BlogRouter';
import Footer from './Components/Footer/Footer';

const rootElement = document.getElementById("root");
render(
    <App/>
    ,rootElement
);


